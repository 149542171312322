<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <Header :event="event" focused/>
      <v-card-title class="headline">
        Configure Registration
      </v-card-title>
      <v-card-text>   
        <p>Use the registration form to allow users to register for your event. After completing the registration form they will receive a confirmation email.</p>
        <p>You can customize the content of the registration form and show/hide specific questions.</p>
      </v-card-text>   

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used.</v-alert>
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

        <v-form v-if="regConfig" ref="form" v-model="valid" lazy-validation>

          <v-card-text>   
            <v-switch 
              v-model="regConfig.enabled"
              label="Enable Registration Form" 
              @change="maybePrepareConfig"
              />
          </v-card-text>   

          <v-expansion-panels v-if="regConfig.enabled" v-model="selectedPanel" accordion tile class="mb-4">
          
          <!-- General -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
              General Options
              <v-spacer/>
              <span class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>          

              <p>Configure the general settings of the registration form for this event.</p>

              <MarkdownEditor v-model="regConfig.intro_msg" :eventId="event.id" label="Intro message (on top of the form)" />

              <v-switch 
                v-model="askForConsent"
                label="Ask for custom consent" 
                hide-details
                />
              <div v-if="askForConsent" class="expansion-options">
                <v-text-field 
                  v-model="regConfig.consent_msg"
                  label="Custom Consent Prompt"
                  hint="Leave empty to hide consent question" 
                  persistent-hint
                  />
              </div>

              <v-switch 
                v-model="regConfig.select_gender"
                label="Ask gender" 
                hide-details
                />
              <div v-if="regConfig.select_gender" class="expansion-options">
                <v-text-field 
                  v-model="regConfig.gender_msg"
                  label="Custom Gender Message"
                  />              

                <v-select
                  v-model="regConfig.gender_options" 
                  :items="siteData.individual_gender_reg_options"
                  item-text="text"
                  item-value="type"
                  label="Gender display" 
                  persistent-hint
                  hint="Select the gender options available in the registration form."
                  />

              </div>

              <v-switch 
                v-model="regConfig.select_dob"
                label="Ask date of birth" 
                hide-details
                />

            </v-expansion-panel-content>          
          </v-expansion-panel>
          
          <!-- Options -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-pencil</v-icon>
              Registration Options
              <v-spacer/>
              <span v-if="regOptions.length>=1" class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
              <span v-if="regOptions.length==0" class="shrink mr-4"><v-icon color="red">fa-times-circle</v-icon> Add at least one option</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>          

              <p>Registration options allow you to create one or more different options users can choose from during registration. Each option is typically linked to a specific set of leaderboards using the import ID. See the docs for more information on this.</p>

              <MarkdownEditor v-model="regConfig.option_msg" :eventId="event.id" label="Registration option message" />

              <p>Manage the registration options (add at least 1 option).</p>
              <v-data-table
                :headers="headers"
                :items="regOptions"
                :items-per-page="100"
                class="mb-8"
                hide-default-footer
                dense
              >
                <template v-slot:top>
                </template>
                <template v-slot:item.id="{ item }">
                  <span class="text-muted">{{item.id}}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <v-text-field v-model="item.name" />
                </template>
                <template v-slot:item.import_id="{ item }">
                  <v-text-field v-model="item.import_id" />
                </template>
                <template v-slot:item.currency="{ item }">
                  <v-text-field v-model="item.currency" :rules="currencyRules" />
                </template>
                <template v-slot:item.amount="{ item }">
                  <DistanceTextArea
                    v-model="item.amount" 
                    :mode="$helpers.UnitType.NUMBER"
                    />                  
                </template>
                <template v-slot:footer>
                  <v-btn text @click="addOption"><v-icon small color="gray" class="mr-2">fa-plus-circle</v-icon> Add an option</v-btn>
                  <span class="text-muted">(To delete an option: clear the name and press Save)</span>
                </template>
              </v-data-table>

            </v-expansion-panel-content>          
          </v-expansion-panel>
          
          <!-- Team -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-users</v-icon>
              Team
              <v-spacer/>
              <v-switch 
                v-model="regConfig.select_team"
                label="Enable" 
                class="shrink pa-0 ma-0 me-8"
                hide-details
                @click.stop=""
                />
              <span class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>          

              <p>You can ask registrants to create or join a team during registration.</p>

              <div v-if="regConfig.select_team" class="">
                <MarkdownEditor v-model="regConfig.team_msg" :eventId="event.id" label="Team selection message" />
                
                <v-switch 
                  v-model="regConfig.block_team_create"
                  hide-details
                  label="Do NOT allow new team to be created" 
                  />

              </div>
              <div v-else>
                <p>Team Registration is disabled on this form.</p>
              </div>
              
            </v-expansion-panel-content>          
          </v-expansion-panel>
          
          <!-- Custom Questions -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-icon color="gray" class="shrink mr-4">fadl fal fa-tasks</v-icon>
              Custom Questions
              <v-spacer/>
              <span class="shrink mr-4"><v-icon color="green">fa-check-circle</v-icon></span>
              <!-- <v-switch 
                v-model="askForCustomQuestions"
                label="Enable" 
                class="shrink pa-0 ma-0 me-8"
                hide-details
                @click.stop=""
                /> -->
            </v-expansion-panel-header>
            <v-expansion-panel-content>          

              <p>You may add up to 3 custom questions to a registration form.</p>
              <MarkdownEditor v-model="regConfig.details_msg" :eventId="event.id" label="Final details custom message" />
              <RegFieldEditor v-model="regConfig.udf_1_field" name="Custom Question 1" />
              <RegFieldEditor v-model="regConfig.udf_2_field" name="Custom Question 2" />
              <RegFieldEditor v-model="regConfig.udf_3_field" name="Custom Question 3" />
            </v-expansion-panel-content>          
          </v-expansion-panel>

          </v-expansion-panels>

        </v-form>

        <v-card-text>   
          <v-btn class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        </v-card-text>   
      </div>      
    </v-card>
    
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import EventHeader from "@/components/EventHeader.vue";
import RegFieldEditor from "@/components/RegFieldEditor.vue";
import ToggleButtonInput from "@/components/ToggleButtonInput.vue";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ToggleButtonInput,
    SupportButton,
    MarkdownEditor,
    EventHeader,
    RegFieldEditor,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      regConfig: null,
      regOptions: [],
      selectedPanel: null,
      valid: true,
      error: null,
      event: null,
      askForConsent: false,
      askForCustomQuestions: false,
      currencyRules: [
        v => !v || v.length === 3 || "Please enter a 3-character currency code (EUR, USD, ...)",
      ],
      headers: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Leaderboard Import Id', value: 'import_id', sortable: false },
        // hide until working Stripe Connect { text: 'Currency', value: 'currency', sortable: false },
        // hide until working Stripe Connect { text: 'Price (leave empty for free)', value: 'amount', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          this.regConfig.options = this.regOptions;
          var response = await eventManagerService.putRegConfig(this.event.id, this.regConfig);
          if (response.data.status === 'OK') {
            this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
            return;
          }
          else {
            this.error = response.data.msg;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      this.regConfig = this.event.reg_config || {};
      this.regOptions = this.regConfig.options || [];
      this.askForConsent = !!this.regConfig.consent_msg;
      this.askForCustomQuestions = this.regConfig.udf_1_field || this.regConfig.udf_2_field || this.regConfig.udf_3_field;
      console.log('RegConfig', this.regConfig);
    },

    async addOption() {
      this.regOptions.push({ id: this.$helpers.randomId()});
    },

    async maybePrepareConfig() {
      console.log('maybePrepareConfig', this.regConfig);

      if (this.regConfig && this.regConfig.enabled) {
        this.selectedPanel = 0;
        // is turned on
        if (!this.regOptions || !this.regOptions.length) {
          this.regOptions.push({ id: 'default', name: 'Default' });
        }
      }

    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Configure Registration', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
  .v-application .v-card .v-form h3 { margin-top: 16px !important; margin-bottom: 0 !important; }

  .expansion-options {
    margin: 0 0 8px 28px;
    padding-left: 16px;
    border-left: solid 2px #ccc;
  }
</style>

